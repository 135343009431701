import React, { useState, useEffect, useRef } from 'react';
import { Terminal, Scan, Loader2, User, Mail, Twitter, Linkedin, Globe } from 'lucide-react';

// Terminal response types
type Response = {
  text: string;
  type: 'system' | 'error' | 'success';
};

interface DomainInfo {
  handle?: string;
  ldhName?: string;
  status?: string[];
  events?: Array<{
    eventAction: string;
    eventDate: string;
  }>;
  entities?: Array<{
    roles: string[];
    ldhName?: string;
    handle?: string;
    vcardArray?: any[];
  }>;
  nameservers?: Array<{
    ldhName: string;
  }>;
}

function App() {
  const [input, setInput] = useState('');
  const [responses, setResponses] = useState<Response[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [scale, setScale] = useState(1);
  const bottomRef = useRef<HTMLDivElement>(null);

  // Auto-scroll to bottom when new responses are added
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [responses]);

  // Simulate typing effect for initial message
  useEffect(() => {
    const initialMessage = '[AGENT KUKUH] Terminal initialized. Ready for commands.';
    let index = 0;
    const timer = setInterval(() => {
      if (index < initialMessage.length) {
        setResponses(prev => [...prev.slice(0, -1), { 
          text: initialMessage.slice(0, index + 1), 
          type: 'system' 
        }]);
        index++;
      } else {
        clearInterval(timer);
      }
    }, 50);
    return () => clearInterval(timer);
  }, []);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const getRegistrar = (info: DomainInfo) => {
    const registrarEntity = info.entities?.find(entity => 
      entity.roles.includes('registrar')
    );
    
    if (registrarEntity?.vcardArray && Array.isArray(registrarEntity.vcardArray[1])) {
      const orgName = registrarEntity.vcardArray[1].find((item: any[]) => 
        item[0] === 'fn' || item[0] === 'org'
      );
      if (orgName && orgName[3]) {
        return orgName[3];
      }
    }
    
    return registrarEntity?.ldhName || 'Not available';
  };

  const getEventDate = (info: DomainInfo, eventType: string) => {
    const event = info.events?.find(e => e.eventAction === eventType);
    return event ? formatDate(event.eventDate) : 'Not available';
  };

  const fetchDomainInfo = async (domain: string): Promise<DomainInfo> => {
    const response = await fetch(`https://rdap.net/domain/${domain}`);
    if (!response.ok) {
      throw new Error(`Domain lookup failed: ${response.statusText}`);
    }
    return response.json();
  };

  const handleDomainCheck = async (domain: string) => {
    try {
      const info = await fetchDomainInfo(domain);
      return [
        '╔════════════════════════════════════╗',
        '║           DOMAIN INFO              ║',
        '╚════════════════════════════════════╝',
        '',
        `Domain: ${info.ldhName || domain}`,
        `Registrar: ${getRegistrar(info)}`,
        `Created: ${getEventDate(info, 'registration')}`,
        `Expires: ${getEventDate(info, 'expiration')}`,
        `Status: ${info.status?.join(', ') || 'Not available'}`,
        `Nameservers: ${info.nameservers?.map(ns => ns.ldhName).join(', ') || 'Not available'}`,
        '',
        '═══════════════════════════════════════'
      ];
    } catch (error) {
      return ['Domain not found or RDAP service unavailable'];
    }
  };

  const handleCommand = async (command: string) => {
    setIsProcessing(true);
    setScale(0.98); // Zoom out effect
    setTimeout(() => setScale(1), 300); // Reset zoom

    const cmd = command.toLowerCase().trim();
    const cleanCmd = cmd.startsWith('/') ? cmd.slice(1) : cmd;
    const parts = cleanCmd.split(' ');

    // Add user input to responses
    setResponses(prev => [...prev, { text: `> ${command}`, type: 'system' }]);

    // Process command
    switch (parts[0]) {
      case 'whois':
        if (!parts[1]) {
          setResponses(prev => [...prev, { 
            text: 'Error: Please specify a domain to check', 
            type: 'error' 
          }]);
          break;
        }
        const domainInfo = await handleDomainCheck(parts[1]);
        for (const line of domainInfo) {
          await new Promise(resolve => setTimeout(resolve, 50));
          setResponses(prev => [...prev, { text: line, type: 'system' }]);
        }
        break;

      case 'fetch':
        await simulateTyping([
          '╔════════════════════════════════════╗',
          '║              ABOUT                 ║',
          '╚════════════════════════════════════╝',
          '',
          '            Kukuh Laksana',
          '            kukuh.id',
          '',
          '╔════════════════════════════════════╗',
          '║             CONTACT                ║',
          '╚════════════════════════════════════╝',
          '',
          '            kukuh@kukuh.id',
          '            x.com/kwkuh',
          '            linkedin.com/in/kukuh-laksana',
          '',
          'Commands: whois [domain], fetch, kukuh'
        ]);
        break;

      case 'kukuh':
        window.location.href = 'https://kukuh.id';
        break;

      case 'clear':
        setResponses([]);
        break;

      default:
        // Check if input looks like a domain
        if (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(cmd)) {
          const domainInfo = await handleDomainCheck(cmd);
          for (const line of domainInfo) {
            await new Promise(resolve => setTimeout(resolve, 50));
            setResponses(prev => [...prev, { text: line, type: 'system' }]);
          }
        } else {
          setResponses(prev => [...prev, { 
            text: 'Command not recognized. Try: whois, fetch, kukuh, or enter a domain name', 
            type: 'error' 
          }]);
        }
    }
    setIsProcessing(false);
  };

  const simulateTyping = async (messages: string[]) => {
    for (const msg of messages) {
      await new Promise(resolve => setTimeout(resolve, 50));
      setResponses(prev => [...prev, { text: msg, type: 'system' }]);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isProcessing) return;
    handleCommand(input);
    setInput('');
  };

  return (
    <div className="min-h-screen w-screen bg-black text-purple-300 font-mono relative overflow-hidden flex flex-col">
      {/* Background Grid Effect */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4a044480_1px,transparent_1px),linear-gradient(to_bottom,#4a044480_1px,transparent_1px)] bg-[size:14px_24px]" />
      
      {/* Noise Effect */}
      <div className="absolute inset-0 opacity-50" 
           style={{ 
             backgroundImage: 'url("data:image/svg+xml,%3Csvg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="3" stitchTiles="stitch"/%3E%3C/filter%3E%3Crect width="100%" height="100%" filter="url(%23noiseFilter)"/%3E%3C/svg%3E")',
             filter: 'contrast(170%) brightness(1000%)'
           }} />

      <div className="relative z-10 flex flex-col h-full" style={{ transform: `scale(${scale})`, transition: 'transform 0.3s ease-out' }}>
        {/* ASCII Logo */}
        <pre className="text-center text-purple-500 text-xs leading-none p-4 md:text-sm lg:text-base">
          {`
   ██╗  ██╗██╗   ██╗██╗  ██╗██╗   ██╗██╗  ██╗
   ██║ ██╔╝██║   ██║██║ ██╔╝██║   ██║██║  ██║
   █████╔╝ ██║   ██║█████╔╝ ██║   ██║███████║
   ██╔═██╗ ██║   ██║██╔═██╗ ██║   ██║██╔══██║
   ██║  ██╗╚██████╔╝██║  ██╗╚██████╔╝██║  ██║
   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝
          `}
        </pre>

        {/* Terminal Window */}
        <div className="flex-1 flex flex-col bg-black/50 backdrop-blur-sm border border-purple-500/30 rounded-lg mx-4 shadow-[0_0_15px_rgba(168,85,247,0.15)]">
          {/* Terminal Header */}
          <div className="flex items-center gap-2 px-4 py-3 border-b border-purple-500/30 bg-purple-900/20">
            <Terminal className="w-5 h-5 text-purple-400" />
            <span className="text-sm md:text-base font-bold text-purple-400">KUKUH AI Terminal v2.0</span>
          </div>

          {/* Terminal Body */}
          <div className="flex-1 p-4 overflow-y-auto">
            {responses.map((response, index) => (
              <div key={index} className={`mb-2 ${
                response.type === 'error' ? 'text-red-400' :
                response.type === 'success' ? 'text-green-400' :
                'text-purple-300'
              } text-sm md:text-base font-bold`}>
                {response.text}
              </div>
            ))}
            {isProcessing && (
              <div className="flex items-center gap-2 text-purple-400">
                <Loader2 className="w-4 h-4 animate-spin" />
                <span className="text-sm font-bold">Processing request...</span>
              </div>
            )}
            <div ref={bottomRef} />
          </div>

          {/* Terminal Input */}
          <form onSubmit={handleSubmit} className="border-t border-purple-500/30 p-4 bg-purple-900/20">
            <div className="flex items-center gap-3 bg-purple-950/50 rounded-lg px-4 py-3 border border-purple-500/30 shadow-[0_0_10px_rgba(168,85,247,0.1)]">
              <span className="text-purple-400 font-bold">{'>>'}</span>
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-1 bg-transparent border-none outline-none text-purple-300 placeholder-purple-700 text-sm md:text-base font-bold"
                placeholder={isProcessing ? 'Processing...' : 'Enter domain or command'}
                disabled={isProcessing}
                autoFocus
              />
              <Scan className={`w-5 h-5 ${isProcessing ? 'text-purple-700 animate-pulse' : 'text-purple-400'}`} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;